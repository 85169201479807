import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | EXbit Web3',
  defaultTitle: 'EXbit',
  description: 'Play Decentralized Web3 Games On EXbit',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@ExbitGames',
    site: '@ExbitGames',
  },
  openGraph: {
    title: 'EXbit GAMES',
    description: 'PLAY DECENTRALIZED WEB3 GAMES ON EXbit',
    images: [{ url: '#' }],
  },
}
