import { languageList, useTranslation } from '@pancakeswap/localization'
import { Menu as UikitMenu, NextLinkFromReactRouter, useModal, Button } from '@pancakeswap/uikit'
import USCitizenConfirmModal from 'components/Modal/USCitizenConfirmModal'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useCakePrice } from 'hooks/useCakePrice'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { IdType } from 'hooks/useUserIsUsCitizenAcknowledgement'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const LinkComponent = (linkProps) => {
  return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
}

const Menu = (props) => {
  const { chainId } = useActiveChainId()
  const { isDark, setTheme } = useTheme()
  const cakePrice = useCakePrice()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [onUSCitizenModalPresent] = useModal(
    <USCitizenConfirmModal title={t('PancakeSwap Perpetuals')} id={IdType.PERPETUALS} />,
    false,
    false,
    'usCitizenConfirmModal',
  )

  const menuItems = useMenuItems(onUSCitizenModalPresent)

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => {
      console.warn(isDark)

      return setTheme(isDark ? 'dark' : 'dark')
    }
  }, [setTheme, isDark])

  return (
    <>
      <UikitMenu
        linkComponent={LinkComponent}
        rightSide={
          <>
            {/* <Button className='exbtn' >
              <a href="https://games.ex-bit.ai" rel="noreferrer" style={{ padding: '0 16px' }} target="_blank">
              Dashboard
              </a>
            </Button> */}
            {/* </div> */}
            <NetworkSwitcher />
            <UserMenu />
          </>
        }
        chainId={chainId}
        banner={false}
        isDark={isDark}
        // toggleTheme={toggleTheme}desktop-icon
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePrice ? Number(cakePrice) : undefined}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : null}
        // footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        // buyCakeLabel={t('Buy EXBIT')}
        // buyCakeLink="https://swap.ex-bit.ai"
        {...props}
      />
    </>
  )
}

export default Menu
