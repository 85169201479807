import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 22 22" {...props}>
      <image
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAvBJREFUSEutlMtPU0EUxr8zF9pC29uiEMNDfKAmxKgLXRgNukFFExMSBE0wJmzk3+DfwA3GiOGlITEKKhvFGE3cKr5QIRQSQC23t/RBZ465Uwq0FqnEWU1mzvnNN985M4QtBndBLB8xjytJDwiABLeY76231AX1t1QnVg8GKDLgP02Cjvnc3E+XI4vMIKvPvGQYuMWgKieOwLMSuGm2Wo+JnLT8Yx3cA4/tDXwEUMvg22ab1bHY56t3CTFOoJ0b0xn8g5Rq8F+zJ7YG96LMLg5MA/AR+KEEugXQA1CFk2x4tWDI6GzmjgsK6DBhjVIbZO4B64qzwAixdgc1ToLwVsJdfVbnJkLPoaJzek7ADBuy3d9ivygIvB5EMHzVcFc3gAxXuhYyiURoHNIOpSsDnk9BtQVhv9yoPK/iDFh4q+CuOgVR7M0SpFaiSMy+gsrYwggJ4k5vm/UoE6jBTvXD/d6jRcJ4A5DbuaRzfU9tI4jWzs6CMzPi02OrtjjG8SJYnfFdtT843UK6Tw+bFxTjDkDlulC+GrgqT/6hNNdHR3ly7jWkPZMp6KIg3Ch9Zz2h8FDghKEwDKBaF8QdRMnei9pTVitIhb8AKpnNFC4UBQ+ARLH2PPZ9BJwIZ2JCUqCZIv2BaRB2Z1aNkl3w7GvS/ixPDoOTVt5WJZeJ0rpmgAixbyNQsfm1OAKmyR4ITDFQmwtmllieuLtZ/+v10vrrIDLyg/mevzzupvMpSb3a31XF2WBKFpVWNTr7qeXZMYB17+UDE7hdpfipLvnSkHlQKPq0KZgo4W8Ne5z9yGAwDmb3ZmAl+FDgivW5MDBICs+Obgem4j87ATb+Ezi/1fms2FKx81xjU8/W/oVctPOASvac0z/Gxq4oAKw/BsjYAqBSOX1cBKOkAiDtyDbAf2229c2CFEMUQ3jKCkSmw1T8F6BW0vONXRG+H9hvSEz+E22TYGmgLtiy9DX9u/XAE/GagwQ0gSG2dQBBMTDqj1qt1IH4b2knm1vadXgdAAAAAElFTkSuQmCC"
        x="0"
        y="0"
        width="22"
        height="22"
      />
    </Svg>
  );
};

export default Icon;
