import { ChainId } from '@pancakeswap/sdk'
import { BIG_ONE } from '@pancakeswap/utils/bigNumber'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import contracts from 'config/constants/contracts'
import { publicClient } from 'utils/wagmi'
import { formatUnits } from 'viem'
import { FAST_INTERVAL } from 'config/constants'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'

// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = () => {
  const { data } = useQuery<BigNumber, Error>({
    queryKey: ['cakePrice'],
    queryFn: async () => new BigNumber(await getCakePriceFromOracle()),
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
  })

  console.debug("price ", data)
  return data
}

export const getCakePriceFromOracle = async () => {
  const cake = await (await fetch('https://gapi.ex-bit.ai/exbit/price')).json()
  return cake.price as string
}
