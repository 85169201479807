import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 19 22" {...props}>
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAWCAYAAAAinad/AAAAAXNSR0IArs4c6QAAA4BJREFUOE+NlG9o1WUUx7/nPPd3793u/hjMFS0KQ3HcVbYIDemvfxgWjEJ6oQWy0QupTF8UOSJboImBEkQRBEb4wv68KNKUZjMSnFRjuatYGGU5EB1z7k537+59nnNO3F1fjG1cd948b8758H2e7/c8FAZcu5m1WnDf9p1wT/91jh+6PkZ1ubxVF/KW8J5iKuBgomAKxFKIR5avrZXs0qXu97Z1fNi58FxkdJr8ad5NQucl2Oq8R3sxTzWhaAge0ACoAhCCmgEGsBkYgCOguho3ahbSIQ30IwdbTD4T2xeLwi4041o4wxlnllYxWAmiuHkSrAQTgAwgJWCqh/+MxvT+8TrUJwjbyWd4r5B+nLgP/4SzNOAUy+YPo0y01loLvVgE5ZfJD/JOqP4cteJYyNBRNrTNF6ZCPyTabF2+F6ugWEVh0G0yb3fGbtcPdJzfoGDd84fxjuGs7m1s4C0qOkL5AdwTGXYKYbdGaEwoHZ8PjMTMBzzp4xhOOOxQxVtkBir8gjUEeoXZPuE4HTI1Nw8DPKXwbPC0GWYfJR7HMQKAktuTp/AYGzZxnNaSs6aKMCFYDEPm6Liqfp58FCeIUPK4XCXHcRILfcy1u7h2qdiiOaMxFRc6TxHvKUZyJPUIhmlqvASYUdaPyDNvZui7FrCgrHBazhSjIq4ryfIZPYUwfXwW7Oa1qdiP9ay03cyWQFALw3VT/GHm3ks+Id/NFDGnsulN1oeqoudPSXWjGB9MJvUlWon8XKCKsG6An/8q1bjkrtwRqD1oRIMXLlY907whd7m8aLNrzmu+/gBSVbfV7tv2zsSaugV6NxGcGWR0lC92baWf/IhsPXAFEzNxs2DdacTHU6mNovJ+4x3c0LzMI1UPjF0FfvsV+O9CGFXwmy4lB74+h2JFA7a01qRjke6fLIblhaBQNQQBRAzFUP5NnEN/Lljn90M4WxHW0RJPE2T/ipW0PJUiXBsBCpMAM1Bbo8iOK3pPol/UOnuu3AL2Wmty9aSEg52vakNLi6GQB6RQfvIEG85kGHs+1KtweOHwEHoqKaNtD1etJ8YXXpQ5ERCLpsIN7w0TOSB3g6AC9aYvfvMvviwvT7lmGdCRxgoi2sUUNalJvRiSKnAqJkW1yRCQLRTtUlC83TOMvlttAHUsRkOg6F6BbxKgPgTEpnwIyJrhUhD8ffQyRqarKkH/B2xBQBf1dWXDAAAAAElFTkSuQmCC"
      x="0"
      y="0"
      width="19"
      height="22"
    />
  </Svg>
);

export default Icon;
